<template>
    <div class="bg-primarybackground rounded-lg text-primarytext p-4 max-w-xl min-w-sm h-fit">
        <img :src="member.profile" alt="Missing Image" class="mx-auto w-48 h-48 mb-2"/>
        <div class="mx-auto text-xl font-bold">{{member.name}}</div>
        <div class="character_description italic" v-html="member.description"></div>

        <div v-if="!member.show_prologue" class="flex items-center pb-2 justify-center">
            <div class="cursor-pointer" @click="togglePrologue">
                <h2 class="italic text-grayedtext inline pr-1">See More</h2>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-grayedtext inline">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                </svg>
            </div>
        </div>
        <div v-else class="flex items-center pb-2 justify-center">
            <div class="cursor-pointer" @click="togglePrologue">
                <h2 class="italic text-grayedtext inline pr-1">See Less</h2>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-grayedtext inline">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                </svg>
            </div>
        </div>

        <div v-if="member.show_prologue" class="character_snapshot" v-html="member.snapshot"></div>    
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'PartyMemberCard',
    props: {
        member: {
            type: Object,
            required: true,
        },
    },
    methods: {
        togglePrologue() {
            this.member.show_prologue = !this.member.show_prologue;
        },
    },
});
</script>

<style>
    .character_snapshot h2{
        @apply text-lg;
    }
</style>