<template>
  <div class="grid h-screen sm:grid-cols-[288px_1fr]"><!-- content wrapper -->
    <NavBar/>
    <router-view class="sm:col-span-1"/>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'

export default {
  name: 'App',
  components: {
    NavBar
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

* {
  margin: 0;
  padding: 0;
}

.app-grid {
  grid-template-columns: min-content auto
}

</style>
