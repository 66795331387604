<template>
    <div class="bg-primarybackground">
        <div v-if="isDataLoaded">
            <div v-for="session in sessions" :key="session.id"  class="parchment bg-no-repeat bg-center bg-cover rounded-lg text-primarybackground p-4 m-4 min-w-sm h-fit" :style="{ backgroundImage: `url(${parchment_background})` }">
                <div v-html="session.value"></div>
            </div>   
        </div>
    </div>
</template>

<script>
import { ref, onMounted, require} from 'vue';
import sessionData from "@/data/campaign_summary.json";
import { marked } from 'marked';

export default {
    name: 'CampaignView',
    data() {
        return {
            parchment_background:  require('@/../public/images/backgrounds/parchment_background.png')
        };
    },
    setup(){
        const isDataLoaded = ref(false);
        const sessions = ref([]);


        const processSessionData = async () => {
            sessionData.sessions.map(async (session) => {
                const response = await fetch(session.file);
                const text = await response.text();
                session.value = marked(text);
                sessions.value = [...sessions.value, session];
            });
            isDataLoaded.value = true;
        };

        onMounted(() => {
            processSessionData();
        });

        return { sessions, isDataLoaded };
    }
}
</script>

<style>
    .parchment h1{
        @apply text-4xl font-bold text-parchmenttext;
        font-family: "Luminari", "Helvetica", sans-serif;
    }
    .parchment h2{
        @apply text-2xl font-bold text-parchmenttext mt-8;
        font-family: "Luminari", "Helvetica", sans-serif;
    }
    .parchment h3{
        @apply text-xl font-bold text-parchmenttext mt-8 border-b-parchmenttext border-b-2 text-left mb-2;
    }
    .parchment img{
        @apply mx-auto object-contain h-48 mt-6;
    }
    .parchment em{
        @apply italic;
    }
    .parchment p{
        @apply text-left font-medium m-4;
    }
    .parchment ol{
        @apply list-decimal text-left pl-8 pr-8;
    }
</style>