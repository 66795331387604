<template>
    <div class="bg-primarybackground rounded-lg text-primarytext p-4 max-w-sm min-w-sm h-fit">
        <img :src="npc.profile" alt="Missing Image" class="mx-auto w-48 h-48 mb-2"/>
        <div class="mx-auto text-xl font-bold">{{npc.name}}</div>
        <div class="character_description italic" v-html="npc.description"></div>
  
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'NPCCard',
    props: {
        npc: {
            type: Object,
            required: true,
        },
    }
});
</script>

<style>
</style>