<template>
    <div class="nav-container"><!-- nav -->
        <nav class="text-left">
            <div class="flex justify-between items-center sm:justify-center pb-2">
                <h1 class="font-bold uppercase p-4 border-b border-gray-100">
                    <a href="/" class="text-primarytext sm:text-lg">From the Thicket</a>
                </h1>
                <div @click="showSections = !showSections" class="px-4 cursor-pointer sm:hidden " id="burger">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </div>
            </div>

            <div v-if="showSections" class="md:hidden">
                <div class="nav-bar-section" v-for="section in sections" :key="section">
                    <NavBarSection :sectiontext="section.sectiontext" :svgpath="section.svgpath" :address="section.address"/>
                </div>
            </div>
            <div class="hidden md:block">
                <div class="nav-bar-section" v-for="section in sections" :key="section">
                    <NavBarSection :sectiontext="section.sectiontext" :svgpath="section.svgpath" :address="section.address"/>
                </div>
            </div>
        </nav>
    </div><!-- end nav -->
</template>

<script>
    import NavBarSection from './NavBarSection.vue'
    export default {
        components: { NavBarSection },
        data() {
            return {
                showSections: true
            };
        },
        setup(){
            const sections = [
                {sectiontext:"What is This?", address:"welcome",svgpath:"M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"},
                {sectiontext:"The Party", address:"party",svgpath:"M13 10V3L4 14h7v7l9-11h-7z"},
                {sectiontext:"The Adventure So Far", address:"campaign",svgpath:"M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"},
                {sectiontext:"Notable Inhabitants",address:"npc",svgpath:"M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"},
                {sectiontext:"Notable Locations", address:"locations",svgpath:"M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"},
                {sectiontext:"History of Azaria", address:"history",svgpath:"M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"},
                {sectiontext:"Useful Files", address:"files",svgpath:"M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0120.25 6v12A2.25 2.25 0 0118 20.25H6A2.25 2.25 0 013.75 18V6A2.25 2.25 0 016 3.75h1.5m9 0h-9"}
            ]

            return {sections}
        }
    }   
</script>
    
<style>
    nav {
        @apply p-5 sm:w-68;
    }

    .router-link-active {
        @apply border-backgroundblue text-backgroundblue
    }

    .nav-container {
        @apply sm:col-span-1 sm:flex sm:justify-center bg-primarybackground sm:w-72;
    }

    .nav-bar-section {
        @apply text-secondarytext font-bold py-1 hover:text-primarytext hover:text-lg transition ease-in-out duration-300;
    }
</style>