<template>
    <div class="bg-no-repeat bg-center bg-cover" :style="{ backgroundImage: `url(${npc_background})` }">
        <div v-if="isDataLoaded" class="lg:hidden flex flex-wrap lg:flex-row gap-8 justify-center p-8 mx-auto max-w-screen-xl">
            <NPCCard v-for="npc in inhabitants" :key="npc.id" :npc="npc"/>
        </div>
        <!-- Two Columns -->
        <div v-if="isDataLoaded" class="hidden lg:flex xl:hidden flex-wrap lg:flex-row gap-8 justify-center p-8 mx-auto max-w-screen-xl">
            <div class="w-2/5">
                <div class="grid grid-cols-1 gap-8">
                    <NPCCard v-for="npc in leftColumn" :key="npc.id" :npc="npc"/>
                </div>
            </div>
            <div class="w-2/5">
                <div class="grid grid-cols-1 gap-8">
                    <NPCCard v-for="npc in rightColumn" :key="npc.id" :npc="npc"/>
                </div>
            </div>
        </div>
        <!-- Three Columns -->
        <div v-if="isDataLoaded" class="hidden xl:flex flex-wrap lg:flex-row gap-8 justify-center p-8 mx-auto max-w-screen-xl">
            <div class="w-[30%]">
                <div class="grid grid-cols-1 gap-8">
                    <NPCCard v-for="npc in left3Column" :key="npc.id" :npc="npc"/>
                </div>
            </div>
            <div class="w-[30%]">
                <div class="grid grid-cols-1 gap-8">
                    <NPCCard v-for="npc in mid3Column" :key="npc.id" :npc="npc"/>
                </div>
            </div>
            <div class="w-[30%]">
                <div class="grid grid-cols-1 gap-8">
                    <NPCCard v-for="npc in right3Column" :key="npc.id" :npc="npc"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, require } from 'vue';
import npcData from "@/data/notable_inhabitants.json";
import { marked } from 'marked';
import NPCCard from '@/components/NPCCard.vue'

export default {
    name: 'NPCView',
    components: {
        NPCCard,
    },
    data() {
        return {
            npc_background:  require('@/../public/images/backgrounds/landscape_background.png'),
        };
    },
    setup(){
        const isDataLoaded = ref(false);
        const inhabitants = ref([]);

        const processNPCData = async () => {
                npcData.inhabitants.map(async (npc) => {
                    const response = await fetch(npc.description_file);
                    const text = await response.text();
                    npc.description = marked(text);

                    inhabitants.value = [...inhabitants.value, npc];
                });
            isDataLoaded.value = true;
        };

        onMounted(() => {
            processNPCData();
        });

        return { inhabitants, isDataLoaded };
    },
    computed: {
        leftColumn() {
            return this.inhabitants.filter((_, i) => i % 2 === 0);
        },
        rightColumn() {
            return this.inhabitants.filter((_, i) => i % 2 === 1);
        },
        left3Column() {
            return this.inhabitants.filter((_, i) => i % 3 === 0);
        },
        mid3Column() {
            return this.inhabitants.filter((_, i) => i % 3 === 1);
        },
        right3Column() {
            return this.inhabitants.filter((_, i) => i % 3 === 2);
        },
    },
}
</script>

<style>

</style>