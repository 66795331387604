<template>
    <div class="bg-no-repeat bg-center bg-cover" :style="{ backgroundImage: `url(${location_background})` }">
        <div v-if="isDataLoaded" class=" flex flex-wrap lg:flex-row gap-8 justify-center p-8 mx-auto max-w-screen-xl">
            <LocationCard v-for="location in locations" :key="location.id" :location="location"/>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, require } from 'vue';
import locationData from "@/data/notable_locations.json";
import { marked } from 'marked';
import LocationCard from '@/components/LocationCard.vue'

export default {
    name: 'LocationsView',
    components: {
        LocationCard,
    },
    data() {
        return {
            location_background:  require('@/../public/images/backgrounds/location_background.png'),
        };
    },
    setup(){
        const isDataLoaded = ref(false);
        const locations = ref([]);

        const processLocationData = async () => {
                locationData.locations.map(async (location) => {
                    const response = await fetch(location.description_file);
                    const text = await response.text();
                    location.description = marked(text);

                    locations.value = [...locations.value, location];
                });
            isDataLoaded.value = true;
        };

        onMounted(() => {
            processLocationData();
        });

        return { locations, isDataLoaded };
    }
}
</script>

<style>
  
</style>