<template>
    <div class="nav-bar-section">
        <router-link class="pr-4 flex border-r-4 border-primary hover:border-textprimary" :to="{name:address}">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" :d="svgpath"/>
            </svg>
            <span>{{sectiontext}}</span>
        </router-link>
    </div>
</template>

<script>
export default {
    props:['sectiontext', 'address','svgpath']
}
</script>

<style>
</style>