import { createRouter, createWebHistory } from 'vue-router'
import WelcomeView from '../views/WelcomeView.vue'
import PartyView from '../views/PartyView.vue'
import CampaignView from '../views/CampaignView.vue'
import NPCView from '../views/NPCView.vue'
import LocationsView from '../views/LocationsView.vue'
import HistoryView from '../views/HistoryView.vue'
import FilesView from '../views/FilesView.vue'
const routes = [
  {
    path: '/',
    name: 'welcome',
    component: WelcomeView
  },
  {
    path: '/party',
    name: 'party',
    component: PartyView
  },
  {
    path: '/campaign',
    name: 'campaign',
    component: CampaignView
  },
  {
    path: '/npc',
    name: 'npc',
    component: NPCView
  },
  {
    path: '/locations',
    name: 'locations',
    component: LocationsView
  },
  
  {
    path: '/history',
    name: 'history',
    component: HistoryView
  },
  {
    path: '/files',
    name: 'files',
    component: FilesView
  },
  //catch all 404
  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    component: WelcomeView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
