<template>
    <div class="bg-no-repeat bg-center bg-cover" :style="{ backgroundImage: `url(${forest_background})` }">
        <!-- One Column -->
        <div v-if="isDataLoaded" class="lg:hidden flex flex-wrap lg:flex-row gap-8 justify-center p-8 mx-auto max-w-screen-xl">
            <div class="grid grid-cols-1 gap-8 w-11/12">
                <FilesCard v-for="file_set in files" :key="file_set.id" :file_set="file_set" />
            </div>
        </div>
        <!-- Two Columns -->
        <div v-if="isDataLoaded" class="hidden lg:flex flex-wrap lg:flex-row gap-8 justify-center p-8 mx-auto max-w-screen-xl">
            <div class="w-2/5">
                <div class="grid grid-cols-1 gap-8">
                    <FilesCard v-for="file_set in leftColumn" :key="file_set.id" :file_set="file_set" />
                </div>
            </div>
            <div class="w-2/5">
                <div class="grid grid-cols-1 gap-8">
                    <FilesCard v-for="file_set in rightColumn" :key="file_set.id" :file_set="file_set" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, require, onMounted } from 'vue';
import filesData from "@/data/downloadables.json";
import FilesCard from '@/components/FilesCard.vue'

export default {
    name: 'FilesView',
    components: {
        FilesCard,
    },
    data() {
        return {
            forest_background: require('@/../public/images/backgrounds/forest_background_2.png'),
        };
    },
    setup(){
        const isDataLoaded = ref(false);
        const files = ref([]);

        const processFilesData = async () => {
            files.value = filesData.files
            isDataLoaded.value = true
        };

        onMounted(() => {
            processFilesData();
        });

        return { files, isDataLoaded };
    },
    computed: {
        leftColumn() {
            return this.files.filter((_, i) => i % 2 === 0);
        },
        rightColumn() {
            return this.files.filter((_, i) => i % 2 === 1);
        }
    },
}
</script>

<style>

</style>






