<template>
    <div class="bg-no-repeat bg-center bg-cover" :style="{ backgroundImage: `url(${party_background})` }">
        <div v-if="isDataLoaded" class="hidden sm:flex flex-wrap lg:flex-row gap-8 justify-center p-8 mx-auto max-w-screen-xl">
            <div class="w-full lg:w-2/5">
                <div class="grid grid-cols-1 gap-8">
                    <PartyMemberCard v-for="member in leftColumn" :key="member.id" :member="member" />
                </div>
            </div>
            <div class="w-full lg:w-2/5">
                <div class="grid grid-cols-1 gap-8">
                    <PartyMemberCard v-for="member in rightColumn" :key="member.id" :member="member" />
                </div>
            </div>
        </div>
        <div v-if="isDataLoaded" class="sm:hidden flex flex-wrap lg:flex-row gap-8 justify-center p-8 mx-auto max-w-screen-xl">
            <div class="grid grid-cols-1 gap-8">
                <PartyMemberCard v-for="member in party" :key="member.id" :member="member" />
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, require } from 'vue';
import partyData from "@/data/party.json";
import { marked } from 'marked';
import PartyMemberCard from '@/components/PartyMemberCard.vue'

export default {
    name: 'PartyView',
    components: {
        PartyMemberCard,
    },
    data() {
        return {
            party_background:  require('@/../public/images/backgrounds/party_background.png'),
        };
    },
    setup(){
        const isDataLoaded = ref(false);
        const party = ref([]);

        const processPartyData = async () => {
                partyData.party.map(async (member) => {
                    const response = await fetch(member.snapshot_file);
                    const text = await response.text();
                    member.snapshot = marked(text);

                    const response2 = await fetch(member.description_file);
                    const text2 = await response2.text();
                    member.description = marked(text2);

                    member.show_prologue = false

                    party.value = [...party.value, member];
                });
            isDataLoaded.value = true;
        };

        onMounted(() => {
            processPartyData();
        });

        return { party, isDataLoaded };
    },
    computed: {
        leftColumn() {
            return this.party.filter((_, i) => i % 2 === 0);
        },
        rightColumn() {
            return this.party.filter((_, i) => i % 2 === 1);
        },
    },
}
</script>

<style>
</style>