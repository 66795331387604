<template>
     <div class="bg-no-repeat bg-center bg-cover" :style="{ backgroundImage: `url(${forest_background})` }">
        <div class="parchment bg-no-repeat bg-center bg-cover rounded-lg text-primarybackground p-4 m-4 min-w-sm h-fit" :style="{ backgroundImage: `url(${parchment_background})` }">
            <h1>Welcome to Azaria!</h1>
            <p> 
                This is the homepage for the world of my onging ttrpg campaign. In Azaria the gods are new, the world is young, and the price of potatoes is irrationally high. 
                If you want to keep up with the campaign, you can follow the story <router-link class="link" :to="{name:campaign_address}">here</router-link>.
                If you'd like to learn more about the world, and you enjoy reading lore, check out the  <router-link class="link" :to="{name:history_address}">history</router-link>. 
                
            </p>
            <p>
                The backgrounds and portraits for this website are AI-generated. The rest of the art is almost entirely my own (the two exceptions are licensed and will be replaced eventually). The writing is a joint effort between me and my partner.
            </p>
        </div>   
        <div class="rounded-lg text-primarybackground p-4 m-4 min-w-sm">
            <img :src="world_map" alt="Missing Image" class="w-full"/>
        </div>
    </div>
</template>

<script>
import { require } from 'vue';

export default {
    name: 'WelcomeView',
    data() {
        return {
            forest_background:  require('@/../public/images/backgrounds/forest_background.png'),
            parchment_background:  require('@/../public/images/backgrounds/parchment_background.png'),
            world_map:  require('@/../public/images/locations/azaria_world_map.jpg'),
            history_address:"history",
            campaign_address:"campaign",
        };
    },
}
</script>

<style>
    .link{
        @apply text-blue-700 underline hover:text-blue-900 focus:outline-none focus:text-blue-900;
    }
    .parchment h1{
        @apply text-4xl font-bold text-parchmenttext;
        font-family: "Luminari", "Helvetica", sans-serif;
    }
    .parchment h2{
        @apply text-2xl font-bold text-parchmenttext mt-8;
        font-family: "Luminari", "Helvetica", sans-serif;
    }
    .parchment h3{
        @apply text-xl font-bold text-parchmenttext mt-8 border-b-parchmenttext border-b-2 text-left mb-2;
    }
    .parchment p{
        @apply font-medium;
    }
</style>