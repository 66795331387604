<template>
    <div class="border border-neutral-500 rounded-lg min-w-sm bg-no-repeat bg-center bg-cover text-primarybackground p-4 m-4 h-fit" :style="{ backgroundImage: `url(${parchment_background})` }">
        <img :src="location.image" alt="Missing Image" class="mx-auto max-w-[768px] mb-2 border border-[#714E2D] rounded-lg"/>
        <div class="mx-auto font-bold fancy_font">{{location.name}}</div>
        <div class="location_description italic" v-html="location.description"></div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'LocationCard',
    data() {
        return {
            parchment_background:  require('@/../public/images/backgrounds/parchment_background.png'),
        };
    },
    props: {
        location: {
            type: Object,
            required: true,
        },
    }
});
</script>

<style>
    .fancy_font{
        @apply text-4xl font-bold text-parchmenttext;
        font-family: "Luminari", "Helvetica", sans-serif;
    }
    .location_description p{
        @apply text-left font-medium pb-2;
    }
</style>