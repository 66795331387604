<template>
    <div class="bg-primarybackground">
        <div v-if="isDataLoaded" class="full_history bg-no-repeat bg-center bg-cover rounded-lg text-primarybackground p-4 m-4 min-w-sm h-fit" :style="{ backgroundImage: `url(${history_background})` }" v-html="history"></div>   
    </div>
</template>

<script>
import { ref, onMounted, require } from 'vue';
import { marked } from 'marked';
import historyData from "@/data/history_of_azaria.json";

export default {
    name: 'HistoryView',
    data() {
        return {
            history_background:  require('@/../public/images/backgrounds/parchment_background.png'),
        };
    },
    setup(){
        const isDataLoaded = ref(false);
        const history = ref([]);

        const processHistoryData = async () => {
            const response = await fetch(historyData.file);
            const text = await response.text();
            history.value = marked(text);

            isDataLoaded.value = true;
        };

        onMounted(() => {
            processHistoryData();
        });

        return { history, isDataLoaded };
    }
}
</script>

<style>
    .full_history h1{
        @apply text-4xl font-bold text-parchmenttext;
        font-family: "Luminari", "Helvetica", sans-serif;
    }
    .full_history h2{
        @apply text-2xl font-bold text-parchmenttext mt-8;
        font-family: "Luminari", "Helvetica", sans-serif;
    }
    .full_history h3{
        @apply text-xl font-bold text-parchmenttext mt-8 border-b-parchmenttext border-b-2 text-left mb-2;
    }
    .full_history img{
        @apply mx-auto object-contain h-48 mt-6;
    }
    .full_history em{
        @apply mx-auto mb-6 block text-center;
    }
    .full_history p{
        @apply text-left font-medium mb-2;
    }
    .full_history ol{
        @apply list-decimal text-left pl-8 pr-8;
    }
</style>