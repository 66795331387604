<template>
    <div class="bg-primarybackground rounded-lg text-primarytext p-4 max-w-xl min-w-sm h-fit overflow-x-hidden w-full">
        <div class="mx-auto text-xl font-bold file_set_title mb-2">{{file_set.name}}</div>
        <div class="flex flex-col items-center">
            <div @click="downloadFile(file.file)" v-for="file in file_set.data" :key="file.id" class="px-2 group flex justify-between items-center w-full mb-1 hover:cursor-pointer hover:font-bold transition ease-in-out duration-300 whitespace-nowrap">
                <div class="pr-4 text-sm md:text-base">{{ file.description }}</div>
                <div class="bg-primarytext rounded-lg inline-flex group-hover:border-2 group-hover:border-backgroundblue px-1">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-4 inline text-primarybackground">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'FilesCard',
    props: {
        file_set: {
            type: Object,
            required: true,
        },
    },
    methods: {
        downloadFile(url) {
            const link = document.createElement("a");
            link.href = url;
            link.download = "";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
});
</script>

<style>
    .file_set_title{
        font-family: "Luminari", "Helvetica", sans-serif;
    }
    .svg_highlight{
        
    }
</style>